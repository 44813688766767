<template>
  <fieldset class="px-3 flex-grow-1">
    <legend>{{ assetName }}</legend>

    <v-text-field
      v-model.trim="referenceNo"
      :label="$t('views.inventory.referenceNo')"
      :rules="referenceNoRule"
      class="px-0"
    />

    <v-radio-group
      v-model="condition"
      :label="$t('views.inventory.condition')"
      :rules="conditionRule"
      class="mt-0"
      dense
      row
    >
      <v-radio
        v-for="n in 5"
        :key="n"
        :color="conditionColors[n - 1]"
        :label="n.toString()"
        :value="n"
      />
    </v-radio-group>

    <v-radio-group
      v-model="roadSide"
      :label="$t('views.inventory.roadSide')"
      :rules="roadSideRule"
      class="mt-0"
      dense
      row
    >
      <v-radio
        :label="$t('enums.side.0')"
        value="left"
      />
      <v-radio
        :label="$t('enums.side.1')"
        value="right"
      />
    </v-radio-group>

    <v-radio-group
      v-model="isOffRoad"
      :label="$t('views.inventory.isOffRoad')"
      :rules="isOffRoadRule"
      class="mt-0"
      dense
      row
    >
      <v-radio
        :label="$t('views.inventory.onRoad')"
        :value="false"
      />
      <v-radio
        :label="$t('views.inventory.offRoad')"
        :value="true"
      />
    </v-radio-group>

    <v-radio-group
      v-if="isSign"
      v-model="signSupport"
      :label="$t('views.inventory.signSupport')"
      :rules="signSupportRule"
      class="mt-0"
      dense
      row
    >
      <v-radio
        v-for="supportType in signSupportTypes"
        :key="supportType"
        :label="$t(`enums.signSupportTypes.${supportType}`)"
        :value="supportType"
      />
    </v-radio-group>

    <label>{{ $t('views.inventory.roadSideDistance') }}</label>
    <v-range-slider
      v-model="roadSideDistance"
      :disabled="disabled"
      :max="maxRoadSideDistance"
      dense
      min="0"
      step="0.1"
      thumb-label
    />

    <label>{{ $t('views.inventory.vehicleDistance') }}</label>
    <v-range-slider
      v-model="vehicleDistance"
      :max="maxVehicleDistance"
      dense
      min="0"
      step="0.1"
      thumb-label
    />

    <v-text-field
      v-model.trim="description"
      :label="$t('views.inventory.description')"
      :rules="descriptionRule"
      class="px-0"
      counter
      maxlength="100"
    />
  </fieldset>
</template>

<script>
import { mapState } from 'vuex'

import { rules } from '@/utils/rules'
import { assetTypes, signSupportTypes } from '@/utils/enum'
import { defaultLengths, defaultWidths } from '@/utils/constants'

export default {
  name: 'AssetsFieldset',

  props: {
    asset: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      referenceNo: this.asset,
      condition: null,
      description: null,
      roadSide: null,
      isOffRoad: null,
      signSupport: null,
      disabled: false,
      roadSideDistance: [0, defaultWidths.road],
      vehicleDistance: [0, defaultLengths.segment],
      maxVehicleDistance: defaultLengths.segment,
      conditionColors: ['#05a57d', '#33cc99', '#ffbd21', '#ff9400', '#ff6666'],

      conditionRule: rules.inventory.condition,
      descriptionRule: rules.inventory.description,
      isOffRoadRule: rules.inventory.isOffRoad,
      roadSideRule: rules.inventory.roadSide,
      referenceNoRule: rules.inventory.referenceNo,
      signSupportRule: rules.inventory.signSupport,

      signs: [
        assetTypes.DANGER_SIGNS,
        assetTypes.INDICATION_SIGNS,
        assetTypes.PRESCRIPTION_SIGNS,
      ],
      signSupportTypes: signSupportTypes.toList(),
    }
  },

  computed: {
    ...mapState('inventory', ['groupToAssetsMap']),
    ...mapState('iri', ['segment']),
    ...mapState('images', ['currentImage']),

    maxRoadSideDistance() {
      return (
        this.currentImage?.roadWidth ||
        this.segment?.roadWidth ||
        defaultWidths.road
      )
    },

    assetName() {
      if (this.asset === 'other') {
        return this.$t('enums.assets.other')
      }

      return this.asset
    },

    isSign() {
      return (
        this.groupToAssetsMap &&
        this.signs.some((sign) => this.groupToAssetsMap[sign]?.has(this.asset))
      )
    },
  },

  watch: {
    isOffRoad() {
      this.disabled = this.isOffRoad === true
      if (this.disabled) {
        this.roadSideDistance = [1, 1]
      } else {
        this.roadSideDistance = [0, 10]
      }
    },

    max() {
      this.roadSideDistance = [0, this.max]
    },
  },

  methods: {
    collectData() {
      const result = {
        assetId: this.asset,
        data: {
          referenceNo: this.referenceNo,
          condition: this.condition,
          isOffRoad: this.isOffRoad,
          roadSide: this.roadSide,
          roadSideDistance: this.roadSideDistance,
          vehicleDistance: this.vehicleDistance,
        },
      }

      if (this.isSign) {
        result.data.signSupport = this.signSupport
      }

      if (this.description) {
        result.data.description = this.description
      }

      return result
    },

    setValues(data) {
      this.referenceNo = data.referenceNo
      this.condition = data.condition
      this.isOffRoad = data.isOffRoad
      this.roadSide = data.roadSide
      this.roadSideDistance = data.roadSideDistance
      this.vehicleDistance = data.vehicleDistance

      if (data.signSupport) {
        this.signSupport = data.signSupport
      }

      if (data.description) {
        this.description = data.description
      }
    },
  },
}
</script>
